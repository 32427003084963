/**
 * @file It contains scripts for dashboard view
 */
import { mapGetters } from "vuex"
import ThemisBarChart from "@/components/shared/bar-chart"

export default {
  name      : "Dashboard",
  components: {
    ThemisBarChart
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      issues                    : "issues/issues",
      loggedInUserPolicies      : "accessControl/loggedInUserPolicies",
      issuesWithUnreadMessages  : "kpis/issuesWithUnreadMessages",
      issuesWithUnansweredReport: "kpis/issuesWithUnansweredReport",
      isLoadingKpis             : "kpis/isLoadingKpis",
      loggedInUserRoleTypes     : "auth/loggedInUserRoleTypes"
    }),
    hasAccessToIssuesPerMonthChart() {
      return !!this.loggedInUserPolicies["Issue view"]
    },
    hasAccessToCommunicationKpi() {
      return !!this.loggedInUserPolicies["Issue view"]
    },
    issuesPerMonth() {
      const issuesPerMonth = [{
        name : "25",
        count: 0
      }, {
        name : "26",
        count: 0
      }, {
        name : "27",
        count: 0
      }, {
        name : "28",
        count: 0
      }, {
        name : "29",
        count: 0
      }, {
        name : "30",
        count: 0
      }, {
        name : "31",
        count: 0
      }, {
        name : "32",
        count: 0
      }, {
        name : "33",
        count: 0
      }, {
        name : "34",
        count: 0
      }, {
        name : "35",
        count: 0
      }, {
        name : "36",
        count: 0
      }]
      for (const issue of this.issues) {
        if (new Date().getYear() === new Date(issue.createdAt).getYear()) {
          issuesPerMonth[new Date(issue.createdAt).getMonth()].count += 1
        }
      }
      return issuesPerMonth
    }
  }
}