/**
 * @file It contains scripts for BarChart Component.
 */

export default {
  name : "BarChart",
  props: {
    xKey  : String,
    yKey  : String,
    data  : Array,
    height: Number,
    width : Number
  },
  data() {
    return {
      redrawToggle: true
    }
  },
  methods: {
    animateLoad() {
      this.$d3.selectAll("rect")
        .data(this.data)
        .transition()
        .delay((d, i) => {
          return i * 150
        })
        .duration(1000)
        .attr("y", d => {
          return this.yScale(d[this.yKey])
        })
        .attr("height", d => {
          return this.svgHeight - this.yScale(d[this.yKey])
        })
    },
    redraw() {
      this.redrawToggle = false
      setTimeout(() => {
        this.redrawToggle = true
        this.animateLoad()
      }, 300)
    }
  },
  computed: {
    dataMax() {
      return this.$d3.max(this.data, d => {
        return d[this.yKey]
      })
    },
    dataMin() {
      return this.$d3.min(this.data, d => {
        return d[this.yKey]
      })
    },
    xScale() {
      return this.$d3.scaleBand()
        .rangeRound([0, this.svgWidth])
        .padding(0.1)
        .domain(
          this.data.map(d => {
            return d[this.xKey]
          })
        )
    },
    yScale() {
      return this.$d3.scaleLinear()
        .rangeRound([this.svgHeight, 0])
        .domain([this.dataMin > 0 ? 0 : this.dataMin, this.dataMax])
    },
    yTicks() {
      return this.yScale.ticks(this.dataMax < 10 ? this.dataMax : 10)
    },
    svgWidth() {
      return this.width - 50
    },
    svgHeight() {
      return this.height - 70
    }
  },
  watch: {
    width: {
      immediate: true,
      handler  : function() {
        this.redraw()
      }
    },
    height: {
      immediate: true,
      handler  : function() {
        this.redraw()
      }
    }
  }
}
